 <template>
  <div class="page">
    <!-- 搜索条件表单 -->
    <el-form class="ser_form" size="small" label-width="90px">
      <el-form-item class="el_form_item" label="流水编号">
        <el-input
          class="el_input"
          v-model="form.ra_num"
          placeholder="流水编号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="用户手机号">
        <el-input
          class="el_input"
          v-model="form.user_tel"
          type="number"
          placeholder="用户手机号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="操作人手机">
        <el-input
          class="el_input"
          v-model="form.admin_user_tel"
          type="number"
          placeholder="操作人手机号搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="充值类型">
        <el-select class="el_input" v-model="form.balance_type" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="对公充值" value="1"></el-option>
          <el-option label="对私充值" value="2"></el-option>
          <el-option label="油费充值" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="备注">
        <el-input class="el_input" v-model="form.mark" clearable></el-input>
      </el-form-item>
      <el-form-item> </el-form-item>
      <el-form-item> </el-form-item>
      <el-form-item class="el_form_item elfrom_item" label="发起时间">
        <div class="block">
          <el-date-picker
            v-model="date_value"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label-width="0" id="button">
        <el-button type="primary" @click="cargo_ser">查询</el-button>
        <el-button type="success" @click="whole_exportFile">全部导出</el-button>
      </el-form-item>
    </el-form>
    <div class="total_info">
      <span>共运费:{{ total.freight_total }}元</span>
      <span>总油费:{{ total.fuel_total }}元</span>
    </div>
    <!-- 表格 -->
    <div class="tab_height">
      <el-table
        :data="list.data"
        :border="true"
        :stripe="true"
        size="small"
        v-loading="list.loading"
      >
        <el-table-column label="流水编号/充值时间" width="200px">
          <template slot-scope="scope">
            <div class="tab_line_item">编号: {{ scope.row.ra_num }}</div>
            <div class="tab_line_item">
              时间: {{ scope.row.creat_time_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用户信息">
          <template slot-scope="scope">
            <div class="tab_line_item">
              {{ scope.row.app_user_info.name }} /
              {{ scope.row.app_user_info.tel }}
            </div>
            <div class="tab_line_item">
              {{ scope.row.app_user_info.company_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作人信息">
          <template slot-scope="scope" v-if="scope.row.admin_user_info">
            <div class="tab_line_item">
              姓名: {{ scope.row.admin_user_info.name }}
            </div>
            <div class="tab_line_item">
              电话: {{ scope.row.admin_user_info.tel }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="充值信息">
          <template slot-scope="scope">
            <div class="tab_line_item">金额: {{ scope.row.much }} 元</div>
            <div class="tab_line_item">
              类型: {{ scope.row.balance_type_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="付款方信息" width="240">
          <template slot-scope="scope">
            <div class="tab_line_item" v-if="scope.row.payment_bank_name">付款方银行 :{{ scope.row.payment_bank_name }} </div>
             <div class="tab_line_item" v-if="scope.row.payment_account">付款方银行号码:{{ scope.row.payment_account }}</div>
          </template>
        </el-table-column>
        <el-table-column label="收款方信息" width="240">
          <template slot-scope="scope">
            <div class="tab_line_item" v-if="scope.row.receipt_bank_name">收款方银行 :{{ scope.row.receipt_bank_name }}</div>
             <div class="tab_line_item" v-if="scope.row.receipt_account">收款方银行号码:{{ scope.row.receipt_account }}</div>
          </template>
        </el-table-column>
         <el-table-column label="流水号">
          <template slot-scope="scope">
            <div class="tab_line_item">{{ scope.row.sequence_code }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="mark"></el-table-column>
        <el-table-column label="操作" width="100px"> 
            <template slot-scope="scope">
            <div>
              <el-button  size="mini" type="text" @click="recharge_edit(scope.row)" style="margin-left: 20px">编辑</el-button>
              <!-- <el-button @click="waybill_input" size="mini" type="text">对应运单</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination
      class="margin-top-xl"
      @size-change="page_size_change"
      @current-change="page_current_change"
      :current-page="page.p"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.num"
      :total="list.total * 1"
      layout="total, sizes,prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 编辑 -->
    <rechargedit
    :is_show="edit.is_show"
    :data="edit.data"
    @init="get_page_data()"
    :title="edit.title"
    ></rechargedit>
    <!-- 输入框 -->
    <!-- <el-dialog
      :visible.sync="waybill.is_show"
      width="40%"
      >
        <el-input
           type="textarea"
           :autosize="{ minRows:20, maxRows: 30}"
           placeholder="请输入内容"
           v-model="waybill.value">
        </el-input>
    </el-dialog> -->
  </div>
</template>

<script>
import rechargedit from './recharge_edit.vue'
export default {
  components:{
    rechargedit
  },
  data() {
    return {
      //搜索条件
      form: {
        ra_num: "", //流水编号
        user_tel: "", //归属用户手机
        admin_user_tel: "", //充值人员手机
        balance_type: "", //余额类型
        mark: "", //备注
        start_time: "", // 开始时间
        end_time: "", // 结束
      },

      //表格数据
      list: {
        loading: false, //loading特效
        data: [], //列表数据
        total: 0, //列表总记录数
      },

      //页码信息
      page: {
        need_num: 1,
        num: 10,
        p: 1,
      },
      //编辑信息
      edit:{
        data:{},
        is_show:0,
        title:'油卡充值信息',
      },
      // 导出数组
      excel_arr:[],
      total: {},
      date_value: [], //时间选择
      start_time: "", // 开始时间
      end_time: "", // 结束时间
      waybill:{
        is_show:false,
        value:'',
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    //读取页面数据
    this.get_page_data();
  },
  methods: {
    //导出
    whole_exportFile() {
      // Loading.service(this.options);
      this.page.num = 3000;
      this.$my.net.req({
        take_over_control: 1,
        data: {
          mod: "finance",
          ctr: "recharge_list_admin",
          ...this.form,
          ...this.page,
          trans_status: this.trans_status,
        },
        callback: (data) => {
          console.log(data);
          if (data.msg.list.length !=0) {
            this.page.p++;
            data.msg.list.forEach(item=>{
              item.creat_time_text = this.$my.other.totime(item.creat_time);
              item.app_user_info_name = item.app_user_info.name
              item.app_user_info_tel = item.app_user_info.tel
              item.app_user_info_company_name = item.app_user_info.company_name

              switch (item.balance_type) {
                case "1":
                  item.balance_type_text = "对公充值";
                  break;
                case "2":
                  item.balance_type_text = "对私充值";
                  break;
                case "4":
                  item.balance_type_text = "油费充值";
                  break;
              }
              this.excel_arr.push(item)
            })
            this.whole_exportFile();
          } else {
            var shunxu=[
              {key:"id",cname:"系统id"},
              {key:"ra_num",cname:"流水编号"},
              {key:"creat_time_text",cname:"充值时间"},
              {key:"app_user_info_name",cname:"用户姓名"},
              {key:"app_user_info_tel",cname:"用户手机号"},
              {key:"app_user_info_company_name",cname:"用户公司名称"},
              {key:"much",cname:"充值金额"},
              {key:"balance_type_text",cname:"充值类型"},
              {key:"payment_bank_name",cname:"付款方银行"},
              {key:"payment_account",cname:"付款方银行卡号"},
              {key:"receipt_bank_name",cname:"收款方银行"},
              {key:"receipt_account",cname:"收款方银行卡号"},
              {key:"sequence_code",cname:"流水号"},
              {key:"mark",cname:"备注"},
            ],str="";
            for(var a in shunxu){
              str+=shunxu[a].cname+",";
            }
            str+="\n";
            for(var i=0;i<this.excel_arr.length;i++){
              for(var a in shunxu){
                str+=`${this.excel_arr[i][shunxu[a].key]}\t,`;
              }
              str+='\r\n';
            }

            str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
            
            //生成下载链接
            let link = document.createElement('a');
            link.href = str;

            //对下载的文件命名
            link.download = "充值信息.csv";

            //下载
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            //清空数组
					  this.excel_arr = []
            this.page.p = 1;
            this.page.num = 10;
          }
        },
      });
    },
    // 编辑
    recharge_edit(e){
      if(e.balance_type != 4){
        this.edit.title = "油卡充值信息"
      }
      this.edit.data = e
      this.edit.is_show++
    },
    //对应运单
    // waybill_input(){
    //   this.waybill.is_show =true
    // },
    //搜索
    cargo_ser() {
	  if(this.date_value){
		   this.form.start_time = parseInt(this.date_value[0] / 1000);
        this.form.end_time = parseInt(this.date_value[1] / 1000);
	  }else{
		  this.form.start_time = ''
		  this.form.end_time = ''
	  }
      this.get_page_data();
    },

    //页宽变化
    page_size_change(num) {
      //置入页码
      this.page.num = num;
      this.page.p =1
      //读取
      this.get_page_data();
    },

    //页码变化
    page_current_change(p) {
      //置入页码
      this.page.p = p;

      //读取
      this.get_page_data();
    },

    //获取货源列表
    get_page_data() {
      //加载中...
      // if(this.list.loading){
      // 	return;
      // }

      //置为加载中
      // this.list.loading=true;

      //读取数据
      this.$my.net.req({
        data: {
          mod: "finance",
          ctr: "recharge_list_admin",
          ...this.form,
          ...this.page,
        },
        callback: (data) => {
          //加载完毕
          this.list.loading = false;

          //总数
          this.list.total = data.max;

          //预处理
          for (let item of data.list) {
            //下单时间
            item.creat_time_text = this.$my.other.totime(item.creat_time);

            //结算方式
            switch (item.balance_type) {
              case "1":
                item.balance_type_text = "对公充值";
                break;
              case "2":
                item.balance_type_text = "对私充值";
                break;
              case "4":
                item.balance_type_text = "油费充值";
                break;
            }
          }

          //渲染
          this.list.data = data.list;
          this.total = data.total;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  //height: calc(100% - 40px);
  overflow: auto;
}
.ser_form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tab_height {
  height: calc(100% - 84px);
}
.el_form_item {
  width: 275px;
}
.el_input {
  width: 94%;
}
.btn_left {
  margin-left: 0;
}
.total_info {
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 1px;
  color: #606266;
  span {
    margin-left: 10px;
  }
}
#button {
  margin-left: 240px;
}
</style>